.controlers{
    position: absolute;
    bottom: 0;
    height: 25%;
    width: 70%;
    justify-content: flex-start;
    background-color: #00000030;
    opacity: 1;
    display: flex;
    flex-direction: column !important;
    z-index: 999;
}
.callDetails{
    padding: 8px;
    color:white;

}
.btns{
    display: flex;
    flex-direction: row !important;
}
.active_controler{
    opacity: 1;
    animation: show 200ms;
}
.inactive_controler{
    opacity: 0;
    animation: hide 200ms;
    display: none !important;

}
@keyframes show {
    from {opacity: 0}
    to {opacity: 1}
}
@keyframes hide {
    from {opacity: 1}
    to {opacity: 0}
}
@media only screen and (max-width: 768px) {
    .controlers{
        width: 100%;
        height: 20%;
    }
}