body{
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: auto;
}
h1{
  margin:0
}
.app{
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  font-family: 'Cabin', sans-serif;
  width: 100%;
  color: black;
}

.app h3{
  text-shadow: 1px 1px 4px black;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.app h3 b{
  color: rgb(252, 212, 33);
}

input{
  width: 400px;
  height: 40px;
  padding:10px;
  border-radius: 8px;
  border: none;
  box-shadow: 1px 1px 0.8px #0c1d2e;
  margin-top: 10px;
  margin-bottom: 4px;
}
button{
  width: 100px;
  height:40px;
  background-color: #ffffff;
  border:none;
  box-shadow: 2px 2px 1px #18619d;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  
}
video{
  width: 200px;
  height: 100%;
}
.form{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.videos{
  display: none;
  justify-content: space-between;
  
}
.active{
  display: flex;
}

.videos div{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
