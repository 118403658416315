.appointments {
  width: 30%;
  /* overflow: auto; */
  /* height: 100vh; */
}

.appointmentsHidden {
  width: 30%;
  /* overflow: auto;
    height: 100vh; */
}

.cell {
  margin: 16px 8px;
  padding: 16px;
  /* border: 1px solid lightgray; */
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0 lightgray;
}

.cell .details {
  font-size: 12px;
  color: #999999;
}

.videoScreen {
  background-color: lightgray;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: sticky;
  top: 0;
}

.videoScreenActive {
  background-color: lightgray;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: sticky;
  top: 0;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterContainer .filterItem {
  border: 1px solid rgb(33, 162, 183);
  color: rgb(33, 162, 183);
  padding: 10px 16px;
  width: 100%;
  margin: 4px;
  text-align: center;
  border-radius: 8px;
}

.filterContainer .filterItemActive {
  border: 1px solid white;
  color: white;
  background-color: rgb(33, 162, 183);
  padding: 10px 16px;
  width: 100%;
  margin: 4px;
  text-align: center;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .appointments {
    width: 100%
  }
  .appointmentsHidden {
    display: none;
  }
  .videoScreen {
    display: none;
  }
  .videoScreenActive {
    display: flex;
    width: 100%;
  }
}